import * as React from "react";
import { withStores } from "stores";
import { RegionChildren } from "src/utils/RegionUtils";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { LazyLoadRegionComponentProps } from "components/flexComponents/LazyLoadRegion/typings";

const LazyLoadRegionComponent: React.FC<LazyLoadRegionComponentProps> = (props) => {
  const { blossomComponent, templateComponent, context } = props;

  if (!templateComponent || !blossomComponent) {
    return null;
  }

  const { lazyloadOffset = true } = templateComponent.config;

  return (
    <LazyLoad className="LazyLoadRegion" context={context} forceLazyLoad hasOffset={lazyloadOffset}>
      <div>
        <RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />
      </div>
    </LazyLoad>
  );
};

export const LazyLoadRegion = withStores("context")(LazyLoadRegionComponent);

export default LazyLoadRegion;
